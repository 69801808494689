import './ScheduleDialog.scss';
import React, { ChangeEvent } from "react";
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog';
import { addHours, addYears, format, fromUnixTime, getUnixTime, isToday, parse, set } from 'date-fns';
import { Body2 } from '@material/react-typography';

interface ScheduleDialogProps {
  isDialogShowing: boolean;
  onScheduleDialogClose: () => void;
  onScheduleDialogAccept: (timestamp: number) => void;
}

enum DialogAction {
  ACCEPT = 'accept',
  DISMISS = 'dismiss',
}

function ScheduleDialog({ isDialogShowing, onScheduleDialogClose, onScheduleDialogAccept }: ScheduleDialogProps) {
  // When the dialog is first opened, default the timestamp to 1 hour from now
  const [timestamp, setTimestamp] = React.useState<number>(() => getUnixTime(addHours(new Date(), 1)))

  function onDialogClose(action: string) {
    onScheduleDialogClose();

    if (action === DialogAction.ACCEPT) {
      onScheduleDialogAccept(timestamp);
    }
  }

  function onDateChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (value === '') return;

    const date = parse(value, 'yyyy-MM-dd', new Date());

    // Change just the date portion of the timestamp
    const previousDate = fromUnixTime(timestamp);
    const newDate = set(previousDate, {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    });

    setTimestamp(getUnixTime(newDate));
  }

  function onTimeChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    if (value === '') return;

    const time = parse(value, 'HH:mm', new Date());

    // Change just the time portion of the timestamp
    const previousTime = fromUnixTime(timestamp);
    const newTime = set(previousTime, {
      hours: time.getHours(),
      minutes: time.getMinutes(),
    });

    setTimestamp(getUnixTime(newTime));
  }

  const now = new Date();
  const yearFromNow = addYears(now, 1);
  const selectedDate = fromUnixTime(timestamp);

  const isDateValid = isToday(selectedDate) || (timestamp >= getUnixTime(now) && timestamp <= getUnixTime(yearFromNow));
  const isTimeValid = timestamp >= getUnixTime(now);
  const isReadyToSchedule = isDateValid && isTimeValid;

  // Disable selections before today and after 1 year from now
  const minDate = format(now, 'yyyy-MM-dd');
  const maxDate = format(yearFromNow, 'yyyy-MM-dd');
  // For time, disable selections before now but only if the date is today
  const minTime = isToday(selectedDate) ? format(now, 'HH:mm') : undefined;

  return (
    <Dialog open={isDialogShowing} onClose={onDialogClose}>
      <DialogTitle>Pick date & time</DialogTitle>
      <DialogContent>
        <div className="schedule-dialog-input">
          <input type="date" className="schedule-dialog-input__date" onChange={event => onDateChange(event)} min={minDate} max={maxDate} value={format(selectedDate, 'yyyy-MM-dd')} />
          {!isDateValid && <Body2 className="schedule-dialog-input__helper-text">Date must be between now and 1 year from now</Body2>}
          <input type="time" className="schedule-dialog-input__time" onChange={event => onTimeChange(event)} min={minTime} value={format(selectedDate, 'HH:mm')} />
          {!isTimeValid && <Body2 className="schedule-dialog-input__helper-text">Time must be in the future</Body2> }
        </div>
      </DialogContent>
      <DialogFooter>
        <DialogButton action={DialogAction.DISMISS}>Cancel</DialogButton>
        <DialogButton disabled={!isReadyToSchedule} action={DialogAction.ACCEPT}>Schedule Results</DialogButton>
      </DialogFooter>
    </Dialog>
  );
}

export default ScheduleDialog;
