import { Body1 } from '@material/react-typography';
import './ResultItem.scss';

interface ResultItemProps {
  isShowing: boolean;
  isLastPick?: boolean;
  pickNumber: number;
  teamName: string;
}

function ResultItem({ isShowing, isLastPick, pickNumber, teamName }: ResultItemProps) {
  return (
    <div className={`result-item ${isShowing ? 'result-item--showing' : ''}`}>
      <Body1 className="result-item__pick-number">
        {pickNumber === 1 && <span>&#x1F389; First Pick &#x1F389;</span>}
        {pickNumber === 2 && !isLastPick && <span>Second Pick</span>}
        {pickNumber === 3 && !isLastPick && <span>Third Pick</span>}
        {isLastPick && <span>Last Pick &#x1F62D;</span>}

        {!isLastPick && ![1, 2, 3].includes(pickNumber) && <span>Pick Number {pickNumber}</span>}
      </Body1>
      <Body1 className="result-item__team-name">{teamName}</Body1>
    </div>
  );
}

export default ResultItem;
