import React from 'react';
import { createBrowserRouter, matchPath, Outlet, RouterProvider, useLocation } from "react-router-dom";
import './vendor.css';
import './App.scss';
import SimpleGenerator from "./SimpleGenerator";
import SimpleGeneratorResults, { loader as resultsLoader } from './SimpleGeneratorResults';
import EspnImporter from "./importers/espn/EspnImporter";
import NotFound404 from './NotFound404';
import Demo from './Demo';
import Contact from './Contact';

declare global {
    interface Window {
        gtag?: any;
    }
}

const router = createBrowserRouter([
    {
        path: "/",
        Component: Layout,
        children: [
            { index: true, Component: SimpleGenerator },
            { path: "results/:id", Component: SimpleGeneratorResults, loader: resultsLoader },
            { path: "demo", Component: Demo },
            // { path: "about", Component: About },
            { path: "contact", Component: Contact },
            { path: "espn-importer", Component: EspnImporter },
        ]
    },
    { path: "*", Component: NotFound404 },
]);

function App() {
    return <RouterProvider router={router} />;
}

const DEFAULT_TITLE = 'Fantasy Draft Order Generator';

function Layout() {
    const location = useLocation();

    React.useEffect(() => {
        function updateTitle(): void {
            const routeToTitle = [
                { route: '/', title: DEFAULT_TITLE },
                { route: '/results/:id', title: 'Results' },
                { route: '/demo', title: 'Demo' },
                { route: '/contact', title: 'Contact' },
                { route: '/espn-importer', title: 'Import From ESPN' },
            ];

            const match = routeToTitle.find(r => matchPath(r.route, location.pathname)) || routeToTitle[0];

            document.title = match.title !== DEFAULT_TITLE ? `${match.title} | League Generator` : DEFAULT_TITLE;
        }

        function updateCanonical(): void {
            let canonical = document.querySelector('link[rel="canonical"]');

            if (!canonical) {
                canonical = document.createElement('link');
                canonical.setAttribute('rel', 'canonical');
                document.head.appendChild(canonical);
            }

            canonical.setAttribute('href', `${window.location.protocol}//${window.location.host}${location.pathname}`);
        }

        if (window.gtag) {
            window.gtag('config', 'UA-22673798-9', {'page_path': location.pathname});
        }

        updateTitle();
        updateCanonical();
    }, [location]);

    return (
    <div className="App">
        <main className="main-content" id="main-content">
            <Outlet/>
        </main>
    </div>
)
    ;
}

export default App;
