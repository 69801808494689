import React from 'react';
import GeneratorResults from './GeneratorResults';
import { addSeconds } from 'date-fns';

function Demo() {
  const [results, setResults] = React.useState<string[]>([]);

  const generateAtTime = addSeconds(new Date(), 11).toISOString();
  const teamNames = ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6', 'Team 7', 'Team 8', 'Team 9', 'Team 10', 'Team 11', 'Team 12'];

  /*
  Fisher-Yates (aka Knuth) shuffle
  */
  const generateResults = (teamNames: string[]): string[] => {
    const array = [...teamNames];

    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  return (
    <GeneratorResults generateAtTime={generateAtTime}
                      leagueName="Demo League"
                      teamNames={teamNames}
                      results={results}
                      onCountdownFinished={() => setResults(generateResults(teamNames))} />
  );
}

export default Demo;
