import React from 'react';
import './NotFound404.scss';
import logo from './logo.png';
import { Link } from 'react-router-dom';

function NotFound404() {
  return (
    <div className="no-match">
      <img src={logo} alt="" className="no-match__logo"/>
      <h1 className="no-match__heading">404</h1>
      <p className="no-match__subheading">Page not found</p>
      <ul className="no-match__suggested-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </div>
  );
}

export default NotFound404
