import React from "react";
import Select from '@material/react-select';
import LeagueNameField from './LeagueNameField';
import TeamTextField from './TeamTextField';
import { Fab } from "@material/react-fab";
import './SimpleGenerator.scss';
import logo from './logo.png';
import SlideUpContent from "./layout/SlideUpContent/SlideUpContent";
import SlideUpContentHeading from "./layout/SlideUpContent/SlideUpContentHeading";
import SlideUpContentBody from "./layout/SlideUpContent/SlideUpContentBody";
import { Link, useNavigate } from 'react-router-dom';
import { Body2 } from '@material/react-typography';
import ScheduleDialog from './schedule-dialog/ScheduleDialog';
import { addSeconds, fromUnixTime, getUnixTime } from 'date-fns';
import Button from '@material/react-button';

interface SelectOption {
    label: string;
    value: string;
    disabled?: boolean;
}

export const DEFAULT_LEAGUE_NAME = 'League Name';
export const DEFAULT_NUMBER_OF_TEAMS = 10;
export const STORAGE_KEYS = {
    'LEAGUE_NAME': 'league-name',
    'TEAM_NAMES': 'team-names',
};

function SimpleGenerator() {
    const [leagueName, setLeagueName] = React.useState<string>(sessionStorage.getItem(STORAGE_KEYS.LEAGUE_NAME) || DEFAULT_LEAGUE_NAME);
    const [teamNames, setTeamNames] = React.useState<string[]>(() => {
        const names: string[] = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.TEAM_NAMES) || '[]');

        return initializeDefaultTeamNames(names, names.length || DEFAULT_NUMBER_OF_TEAMS);
    });
    const [showScheduleDialog, setShowScheduleDialog] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => window.sessionStorage.setItem(STORAGE_KEYS.LEAGUE_NAME, leagueName), [leagueName]);
    React.useEffect(() => window.sessionStorage.setItem(STORAGE_KEYS.TEAM_NAMES, JSON.stringify(teamNames)), [teamNames]);

    function initializeDefaultTeamNames(existingNames: string[], howMany: number): string[] {
        const names = Array.from({length: howMany}, (v, i) => `Team ${i + 1}`);

        // If we have an existing team name at the current index, use that
        return names.map((name, index) => existingNames[index] || name);
    }

    function onChange(evt: React.ChangeEvent<HTMLSelectElement>) {
        const numberOfTeams = +(evt.target.value || 0);

        setTeamNames(initializeDefaultTeamNames(teamNames, numberOfTeams));
    }

    function updateTeamName(index: number, newTeamName: string) {
        setTeamNames(teamNames.map((teamName: string, i: number) => {
            return i === index ? newTeamName : teamName;
        }));
    }

    async function doScheduleAndNavigate(timestamp: number): Promise<void> {
        // Ensure the generate_at_time is at least 10 seconds from now
        if (timestamp < getUnixTime(addSeconds(new Date(), 10))) {
            timestamp = getUnixTime(addSeconds(new Date(), 10));
        }

        const params = {
            // Send as UTC ISO-8601 string
            generate_at_time: fromUnixTime(timestamp).toISOString(),
            league_name: leagueName,
            team_names: teamNames,
        };

        // Send a POST request to the backend
        try {
            // Utilize CRA's proxy to send the request to the backend to avoid CORS issues
            // https://create-react-app.dev/docs/proxying-api-requests-in-development
            const url = process.env.NODE_ENV === 'development' ? '/v1/create' : `${process.env.REACT_APP_API_URL}/v1/create`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            });

            const json = await response.json();

            if (!response.ok) {
                throw response.status === 400 ? new ClientRequestError(json.error) : new Error();
            }

            navigate(`/results/${json.id}`);
        } catch (e) {
            const msg = e instanceof ClientRequestError ? e.message : 'An error occurred. Please try again later.';
            alert(msg);
        }
    }

    const selectOptions: SelectOption[] = [{label: 'Select Number Of Teams', value: '', disabled: true}];

    for (let i = 2; i <= 40; i++) {
      selectOptions.push({ label: `${i} Teams`, value: i.toString() });
    }

    return (
      <>
          <SlideUpContent>
              <SlideUpContentHeading logo={logo}>
                  <div className="heading-content">
                      <span className="heading-content__heading">Schedule. Share. Watch.</span>
                      <span className="heading-content__subheading">No Account Needed</span>
                      <Body2 className="heading-content__description">
                          A neutral way to randomize the draft order of your fantasy league, so your friends don't think you
                          cheated to get the top pick!
                      </Body2>
                  </div>
              </SlideUpContentHeading>
              <SlideUpContentBody>
                  <div id="simple-generator" className="simple-generator">
                      <div className="simple-generator__app-content">
                          <Link to="demo" className="simple-generator__demo-link">
                              <Button trailingIcon={<span className="material-icons">play_circle_filled</span>}>Demo</Button>
                          </Link>

                          <div className="simple-generator__generator">
                              <div className="simple-generator__generator-input-actions">
                                  <Fab className="simple-generator__generator-schedule-button"
                                       onClick={() => setShowScheduleDialog(true)} textLabel="Schedule"
                                       icon={<span className="material-icons">edit_calendar</span>}/>
                              </div>
                              <div
                                className="simple-generator__generator-section-heading simple-generator__generator-section-heading-league">
                                  <Body2>League</Body2>
                              </div>
                              <div className="simple-generator__generator-input-league-name">
                                  <LeagueNameField leagueName={leagueName}
                                                   onLeagueNameChange={(newLeagueName: string) => setLeagueName(newLeagueName)}/>
                              </div>
                              <div className="simple-generator__generator-input-number-teams">
                                  <Select
                                    label='How Many Teams?'
                                    value={teamNames.length.toString()}
                                    options={selectOptions}
                                    onChange={onChange}
                                  >
                                  </Select>
                              </div>
                              <div
                                className="simple-generator__generator-section-heading simple-generator__generator-section-heading-teams">
                                  <Body2>Teams</Body2>
                                  {/*<Button onClick={() => navigate('/simple-generator/espn-importer')} trailingIcon={<span className="material-icons">cloud_download</span>}>Import From ESPN</Button>*/}
                              </div>
                              <div className="simple-generator__generator-teams">
                                  {teamNames.map((teamName: string, index: number) =>
                                    <div className="simple-generator__generator-team-input" key={index}>
                                        <TeamTextField teamName={teamName}
                                                       onTeamNameChange={(newTeamName: string) => updateTeamName(index, newTeamName)}/>
                                    </div>
                                  )}
                              </div>
                          </div>
                      </div>

                      <ScheduleDialog
                        isDialogShowing={showScheduleDialog}
                        onScheduleDialogClose={() => setShowScheduleDialog(false)}
                        onScheduleDialogAccept={(timestamp: number) => doScheduleAndNavigate(timestamp)} />

                  </div>
              </SlideUpContentBody>
          </SlideUpContent>
      </>
    );
}

/**
 * Error thrown when a request to the backend fails with a 400 status code.
 */
class ClientRequestError extends Error {
    constructor(public message: string) {
        super(message);
    }
}

export default SimpleGenerator;
