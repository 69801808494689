import './NavHeader.scss';
import logo from './logo.png';
import React from 'react';
import { Link } from 'react-router-dom';

function NavHeader() {
  return (
    <div className="nav-header">
      <div className="nav-header__logo">
        <Link to="/"><img src={logo} alt=""/></Link>
      </div>
      <Link to="/" className="nav-header__link">Create</Link>
      {/*<Link to="/about" className="nav-header__link">About</Link>*/}
      <Link to="/contact" className="nav-header__link">Contact</Link>
    </div>
  );
}

export default NavHeader;
