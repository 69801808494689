import './SharePage.scss';
import React from 'react';
import TextField, { Input } from '@material/react-text-field';
import MaterialIcon from '@material/react-material-icon';

interface SharePageProps {
  className? : string;
}

function SharePage({ className }: SharePageProps) {
  const pageUrl = window.location.href;
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  function onClickInput(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    // Highlight the text in the input, so it can be copied
    (evt.target as HTMLInputElement).select();
  }

  function onClickIcon() {
    // Change the icon to a checkmark temporarily
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);

    // Copy the text to the clipboard
    navigator.clipboard.writeText(pageUrl);
  }

  return (
    <div className={`share-page ${className}`}>
      <TextField
        className="share-page__text-field"
        label="Share Page"
        outlined
      >
        <Input className="share-page__input" onClick={onClickInput} value={pageUrl} />
      </TextField>

      <MaterialIcon onClick={onClickIcon} className={`share-page__icon ${isCopied ? 'share-page__icon--copied' : ''}`} role="button" icon={isCopied ? 'done' : 'content_copy'}/>
    </div>
  )
}

export default SharePage;
