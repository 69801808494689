import "./SimpleGeneratorResults.scss";
import React from "react";
import { LoaderFunction, redirect, useLoaderData } from 'react-router-dom';
import GeneratorResults from './GeneratorResults';

export interface ResultsResponse {
    id: string;
    isFinished: boolean;
    generateAtTime: string;
    leagueName: string;
    teamNames: string[];
}

export const loader: LoaderFunction = async ({ params }) => {
    // Utilize CRA's proxy to send the request to the backend to avoid CORS issues
    // https://create-react-app.dev/docs/proxying-api-requests-in-development
    const url = process.env.NODE_ENV === 'development' ? `/v1/get/${params.id}` : `${process.env.REACT_APP_API_URL}/v1/get/${params.id}`;
    const response = await fetch(url).then(res => res.json()).catch(err => console.log(err));

    if (!response) {
        return redirect('/');
    }

    return response;
};

function SimpleGeneratorResults() {
    const response: ResultsResponse = useLoaderData() as ResultsResponse;
    const resultId = response.id;

    const [results, setResults] = React.useState<string[]>(() => response.isFinished ? response.teamNames : []);

    /**
     * Called when the countdown finishes. Not called if the results are already finished.
     */
    function onCountdownFinished() {
        // Using setTimeout to delay a bit so the "backend" has time to update the results
        setTimeout(async () => {
            // Get the latest results
            const url = process.env.NODE_ENV === 'development' ? `/v1/get/${resultId}` : `${process.env.REACT_APP_API_URL}/v1/get/${resultId}`;
            const response = await fetch(url).then(res => res.json()).catch(err => console.log(err));

            // Ensure the response is the isFinished variant. There is some bug where under some conditions it seems to return the unfinished variant,
            // which causes the results to display the incorrect order! We'd rather not display the results at all than display them in the wrong order.
            if (!response || !response.isFinished) {
                return;
            }

            // Set all the results at once and let the useEffect handle the animation
            setResults(response!.teamNames);
        }, 1000);
    }

    return (
      <GeneratorResults generateAtTime={response.generateAtTime}
                        leagueName={response.leagueName}
                        teamNames={response.teamNames}
                        results={results}
                        onCountdownFinished={onCountdownFinished} />
    )
}

export default SimpleGeneratorResults;
