import './Countdown.scss';
import React from 'react';
import { Body2 } from '@material/react-typography';
import { differenceInSeconds } from 'date-fns';

interface CountdownProps {
  countdownTo: Date;
  onCountdownFinished: () => void;
}

function Countdown({ countdownTo, onCountdownFinished }: CountdownProps) {
  const [timeLeft, setTimeLeft] = React.useState<number>(() => differenceInSeconds(countdownTo, new Date()));
  const [isFinished, setIsFinished] = React.useState<boolean>(() => timeLeft <= 0);

  React.useEffect(() => {
    if (isFinished) return;

    if (timeLeft > 0) {
      // Calculate the new time left. Don't just decrement by 1 second because the browser may have been paused.
      const id = setTimeout(() => setTimeLeft(differenceInSeconds(countdownTo, new Date())), 1000);
      return () => clearTimeout(id);
    }

    if (timeLeft === 0) {
      setIsFinished(true);
      onCountdownFinished();
    }
  }, [timeLeft, countdownTo, isFinished, onCountdownFinished]);

  const days = isFinished ? 0 : Math.floor(timeLeft / 86400);
  const hours = isFinished ? 0 : Math.floor((timeLeft % 86400) / 3600);
  const minutes = isFinished ? 0 : Math.floor(((timeLeft % 86400) % 3600) / 60);
  const seconds = isFinished ? 0 : Math.floor(((timeLeft % 86400) % 3600) % 60);

  return (
    <div className="timers">
      <div className="days">
        <div className="numbers"><Body2>{days}</Body2></div>
        <div className="text"><Body2>Days</Body2></div>
      </div>
      <div className="hours">
        <div className="numbers"><Body2>{hours}</Body2></div>
        <div className="text"><Body2>Hours</Body2></div>
      </div>
      <div className="minutes">
        <div className="numbers"><Body2>{minutes}</Body2></div>
        <div className="text"><Body2>Minutes</Body2></div>
      </div>
      <div className="seconds">
        <div className="numbers"><Body2>{seconds}</Body2></div>
        <div className="text"><Body2>Seconds</Body2></div>
      </div>
    </div>
  );
}

export default Countdown;
