import './GeneratorResults.scss';
import { Body1, Headline4, Headline5 } from '@material/react-typography';
import { DEFAULT_LEAGUE_NAME } from './SimpleGenerator';
import SharePage from './SharePage';
import Countdown from './countdown/Countdown';
import LinearProgress from '@material/react-linear-progress';
import { format } from 'date-fns';
import Button from '@material/react-button';
import Dialog, { DialogButton, DialogContent, DialogFooter, DialogTitle } from '@material/react-dialog';
import ResultItem from './ResultItem';
import React from 'react';
import NavHeader from './NavHeader';
import { ResultsResponse } from './SimpleGeneratorResults';

interface GeneratorResultsProps extends Pick<ResultsResponse, 'generateAtTime' | 'leagueName' | 'teamNames'> {
  results: string[];
  onCountdownFinished: () => void;
}

const DELAY = 3000; // 3 seconds

function GeneratorResults({ generateAtTime, leagueName, teamNames, results, onCountdownFinished }: GeneratorResultsProps) {
  const totalTeams = teamNames.length;
  const isFinished = results.length > 0;

  const [totalShowing, setTotalShowing] = React.useState<number>(() => {
    // The generateAtTime has not passed yet, so we should show 0 results
    if (!isFinished) return 0;

    // If the user refreshes the page while the results are still being generated, calculate how many results should be showing
    // based on the current time and the generateAtTime
    const differenceInSeconds = Math.floor((new Date().getTime() - new Date(generateAtTime).getTime()) / 1000);
    const totalTeamsToShow = Math.floor(differenceInSeconds / (DELAY / 1000));

    return totalTeamsToShow >= totalTeams ? totalTeams : totalTeamsToShow;
  });
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  // Animate the results if we have them but haven't shown them all yet
  React.useEffect(() => {
    if (results.length === 0 || totalShowing === totalTeams) return;

    const id = setTimeout(() => setTotalShowing(totalShowing => totalShowing + 1), DELAY);
    return () => clearTimeout(id);
  }, [results, totalShowing, totalTeams]);

  /**
   * Returns true if the result at the given index should be showing
   */
  function isResultIndexShowing(index: number): boolean {
    return index >= results.length - totalShowing;
  }

  const isShowingAllResults = totalShowing === totalTeams;

  return (
    <div className="generator-results-page">
      <NavHeader />

      <div className="generator-results-container">
        <div className="results-heading">
          <Headline4 className="results-heading__title">Draft Order</Headline4>
          {leagueName !== DEFAULT_LEAGUE_NAME &&
            <Headline5 className="results-heading__league-name">{leagueName}</Headline5>
          }
          <SharePage className="results-heading__share-page"/>
        </div>

        {!isShowingAllResults &&
          <div className="progress-bar-container">
            <Countdown countdownTo={new Date(generateAtTime)}
                       onCountdownFinished={onCountdownFinished}/>
            <LinearProgress className="progress-bar" progress={totalShowing / totalTeams}/>
          </div>
        }

        <Body1 className="countdown-to-text">Results {isShowingAllResults ? 'were' : 'will be'} generated
          on {format(new Date(generateAtTime), 'PPPPp')}</Body1>


        {results.length === 0 &&
          <div className="show-teams-button-container">
            <Button className="show-teams-button" onClick={() => setIsDialogOpen(true)}>Show Teams</Button>

            <Dialog className="show-teams-dialog" open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
              <DialogTitle className="show-teams-dialog__title">{teamNames.length} Teams<br/>(Pending Order
                Generation)</DialogTitle>
              <DialogContent className="show-teams-dialog__content">
                <div className="show-teams-dialog__teams-container">
                  {teamNames.map((teamName, index) => {
                    return (
                      <div className="show-teams-dialog__team" key={index}>
                        <div className="show-teams-dialog__team-name">{teamName}</div>
                      </div>
                    )
                  })}
                </div>
              </DialogContent>
              <DialogFooter className="show-teams-dialog__footer">
                <DialogButton action='dismiss'>Close</DialogButton>
              </DialogFooter>
            </Dialog>
          </div>
        }

        {results.length > 0 &&
          <div className="result-items-container">
            {results.map((result, index) =>
              <ResultItem key={index} isShowing={isResultIndexShowing(index)}
                          isLastPick={index + 1 === totalTeams}
                          pickNumber={totalTeams - results.length + index + 1} teamName={result}/>
            )}
          </div>
        }
      </div>
    </div>
  )
    ;
}

export default GeneratorResults;
