import './Contact.scss';
import NavHeader from './NavHeader';
import React from 'react';
import { Body1, Headline3, Headline5 } from '@material/react-typography';
import MaterialIcon from '@material/react-material-icon';

function Contact() {
  return (
    <div>
      <NavHeader />

      <div className="contact">
        <Headline3 className="contact__heading">How can we help?</Headline3>
        <Body1 className="contact__subheading">Questions? Comments? Concerns? Here's how you can get in touch with us.</Body1>

        <div className="contact__content">
          <div className="contact__contact-items">
            <div className="contact-item">
              <MaterialIcon icon="mail" className="contact-item__icon"/>
              <Headline5 className="contact-item__heading">General Inquiries</Headline5>
              <a className="contact-item__link"
                 href="mailto:support@fantasydraftordergenerator.com">support@fantasydraftordergenerator.com</a>
            </div>

            <div className="contact-item">
              <MaterialIcon icon="bug_report" className="contact-item__icon"/>
              <Headline5 className="contact-item__heading">Report a Bug</Headline5>
              <a className="contact-item__link"
                 href="mailto:support@fantasydraftordergenerator.com">bugs@fantasydraftordergenerator.com</a>
            </div>

            <div className="contact-item">
              <MaterialIcon icon="lightbulb_outline" className="contact-item__icon"/>
              <Headline5 className="contact-item__heading">Feature Request</Headline5>
              <a className="contact-item__link"
                 href="mailto:support@fantasydraftordergenerator.com">features@fantasydraftordergenerator.com</a>
            </div>
          </div>
          <img className="contact__screenshot" src="/images/screenshot.png" alt="Fantasy draft order generator landing page."/>
        </div>
      </div>

      <div className="footer">
        © Copyright {new Date().getFullYear()} fantasydraftordergenerator.com. All Rights Reserved.
      </div>
    </div>
  );
}

export default Contact;
