import React from "react";
import { STORAGE_KEYS as GENERATOR_STORAGE_KEYS } from "../../SimpleGenerator";
import LoginPanel from "../common/LoginPanel";
import logo from "./espn-fantasy-football.png";
import './EspnImporter.scss'
import ImportLeagueSelection from "../common/ImportLeagueSelection";
import { useNavigate } from 'react-router-dom';

export const STORAGE_KEYS = {
    'ESPN_LEAGUES': 'espn-leagues',
};

function EspnImporter() {
    const [leagues, setLeagues] = React.useState<League[]>(() => JSON.parse(localStorage.getItem(STORAGE_KEYS.ESPN_LEAGUES) || '[]'));
    const [showingLeagues, setShowingLeagues] = React.useState<boolean>(leagues.length > 0);

    const navigate = useNavigate();

    function selectLeague(league: League) {
        window.sessionStorage.setItem(GENERATOR_STORAGE_KEYS.LEAGUE_NAME, league.leagueName);
        window.sessionStorage.setItem(GENERATOR_STORAGE_KEYS.TEAM_NAMES, JSON.stringify(league.teams));
        navigate('/');
    }

    async function fetchLeagues(username: string, password: string): Promise<League[]> {
        // const response = await fetch(`http://localhost:8000?username=${username}&password=${password}`);
        // const leagues = await response.json();
        //
        // window.localStorage.setItem(STORAGE_KEYS.ESPN_LEAGUES, JSON.stringify(leagues));
        // setLeagues(leagues);
        // setShowingLeagues(true);
        //
        // return leagues;
        setLeagues([]);
        return [];
    }

    return (
      <div className="espn-importer">
          { !showingLeagues &&
            <LoginPanel
              headingText="Enter your ESPN credentials to automatically import all team names for your league."
              logo={logo}
              primaryColor="#39b14a"
              buttonLabel="Check Leagues"
              onLoginButtonClick={(username: string, password: string) => fetchLeagues(username, password)} />
          }

          {showingLeagues &&
            <ImportLeagueSelection leagues={leagues} onLeagueSelected={selectLeague} onRecheckSelected={() => setShowingLeagues(false)}></ImportLeagueSelection>
          }
      </div>
    );
}

export interface League {
    id: string;
    leagueName: string;
    teamName: string;
    teams: string[];
}

export default EspnImporter;
